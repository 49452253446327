/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding initialization code.
 * Use "quasar new plugin <name>" and add it there.
 * One plugin per concern. Then reference the file(s) in quasar.conf.js > plugins:
 * plugins: ['file', ...] // do not add ".js" extension to it.
 **/



import 'quasar-extras/ionicons/ionicons.css'



import 'quasar-extras/animate/fadeIn.css'

import 'quasar-extras/animate/fadeOut.css'

import 'quasar-extras/animate/fadeInUp.css'

import 'quasar-extras/animate/fadeInDown.css'

import 'quasar-extras/animate/fadeOutUp.css'

import 'quasar-extras/animate/fadeOutDown.css'

import 'quasar-extras/animate/fadeInRight.css'

import 'quasar-extras/animate/fadeInLeft.css'

import 'quasar-extras/animate/fadeOutRight.css'

import 'quasar-extras/animate/fadeOutLeft.css'

import 'quasar-extras/animate/slideOutRight.css'

import 'quasar-extras/animate/slideOutLeft.css'

import 'quasar-extras/animate/bounceIn.css'

import 'quasar-extras/animate/bounceOut.css'

import 'quasar-extras/animate/bounceInRight.css'

import 'quasar-extras/animate/bounceOutRight.css'

import 'quasar-extras/animate/slideInLeft.css'

import 'quasar-extras/animate/slideInRight.css'

import 'quasar-extras/animate/slideInUp.css'

import 'quasar-extras/animate/slideInDown.css'

import 'quasar-extras/animate/slideOutUp.css'

import 'quasar-extras/animate/slideOutDown.css'

import 'quasar-extras/animate/zoomIn.css'

import 'quasar-extras/animate/zoomOut.css'

import 'quasar-extras/animate/flipInY.css'

import 'quasar-extras/animate/flipOutY.css'

import 'quasar-extras/animate/tada.css'

import 'quasar-extras/animate/headShake.css'


import 'quasar-app-styl'


import 'src/css/app.styl'


import Vue from 'vue'
import createApp from './app.js'


import 'app/src-pwa/register-service-worker.js'



import pMasonry from 'src/plugins/masonry'

import pMagic from 'src/plugins/magic'

import pI18n from 'src/plugins/i18n'

import pAxios from 'src/plugins/axios'

import pAuth0 from 'src/plugins/auth0'

import pGuid from 'src/plugins/guid'

import pAis from 'src/plugins/ais'

import pVnumber from 'src/plugins/vnumber'

import pPhonenumberinput from 'src/plugins/phone-number-input'

import pConfetti from 'src/plugins/confetti'





import FastClick from 'fastclick'










const { app, store, router } = createApp()



  // Needed only for iOS PWAs
if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && window.navigator.standalone) {

  document.addEventListener('DOMContentLoaded', () => {
    FastClick.attach(document.body)
  }, false)

}




;[pMasonry,pMagic,pI18n,pAxios,pAuth0,pGuid,pAis,pVnumber,pPhonenumberinput,pConfetti].forEach(plugin => {
  plugin({
    app,
    router,
    store,
    Vue,
    ssrContext: null
  })
})









new Vue(app)






